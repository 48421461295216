import React, {useEffect, useRef} from "react";
import * as styles from "./cookies-management.module.scss";
import Layout from "../../components/layout/layout";
import {graphql} from "gatsby";
import {CookiesManagementQuery} from "../../../graphql-types";
import PageCover from "../../components/common/page-cover/page-cover";
import LinksGroupSide from "../../components/common/links-group-side/links-group-side";
import RichDatoCmsContent from "../../components/rich-dato-cms-content/rich-dato-cms-content";
import useWindowWidth from "../../hooks/use-window-width";

type ReactProps = {
    data: CookiesManagementQuery
}

const CookiesManagementPage:React.FC<ReactProps> = ({data}) => {

    const isMobile = useWindowWidth() <= 1200;
    const scriptContainer = useRef(null);
    
    useEffect(() => {
        const script = document.createElement("script")
        const head = document.getElementsByTagName("head")
    
        script.type = "text/javascript"
        script.text =
          `window.gdprAppliesGlobally=true;(function(){function a(e){if(!window.frames[e]){if(document.body&&document.body.firstChild){var t=document.body;var n=document.createElement("iframe");n.style.display="none";n.name=e;n.title=e;t.insertBefore(n,t.firstChild)}\n` +
          `else{setTimeout(function(){a(e)},5)}}}function e(n,r,o,c,s){function e(e,t,n,a){if(typeof n!=="function"){return}if(!window[r]){window[r]=[]}var i=false;if(s){i=s(e,t,n)}if(!i){window[r].push({command:e,parameter:t,callback:n,version:a})}}e.stub=true;function t(a){if(!window[n]||window[n].stub!==true){return}if(!a.data){return}\n` +
          `var i=typeof a.data==="string";var e;try{e=i?JSON.parse(a.data):a.data}catch(t){return}if(e[o]){var r=e[o];window[n](r.command,r.parameter,function(e,t){var n={};n[c]={returnValue:e,success:t,callId:r.callId};a.source.postMessage(i?JSON.stringify(n):n,"*")},r.version)}}\n` +
          `if(typeof window[n]!=="function"){window[n]=e;if(window.addEventListener){window.addEventListener("message",t,false)}else{window.attachEvent("onmessage",t)}}}e("__tcfapi","__tcfapiBuffer","__tcfapiCall","__tcfapiReturn");a("__tcfapiLocator");(function(e){\n` +
          `var t=document.createElement("script");t.id="spcloader";t.type="text/javascript";t.async=true;t.src="https://sdk.privacy-center.org/"+e+"/loader.js?target="+document.location.hostname;t.charset="utf-8";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n)})("2127e597-3d3f-4aa2-9727-c8126f86298c")})();`
    
        head[0].prepend(script)
      }, [])


    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={data.datoCmsWebCookieManagement.seo} pageTemplate="cookiesPage">
            <PageCover
                title={data.datoCmsWebCookieManagement.cover[0] && data.datoCmsWebCookieManagement.cover[0].title}
                image={data.datoCmsWebCookieManagement.cover[0] && data.datoCmsWebCookieManagement.cover[0].backgroundImage}
            />
            <section className={styles.pageContainer}>
                <div className={styles.pageContent}>
                    {!isMobile && <LinksGroupSide data={data.datoCmsWebCookieManagement.linksGroup} />}
                    <div className={styles.content}>
                        <RichDatoCmsContent data={data.datoCmsCookiemanagement.content} />
                        <div ref={scriptContainer} style={{paddingLeft: isMobile ? 0 : 100}} />
                    </div>
                    {isMobile && <LinksGroupSide data={data.datoCmsWebCookieManagement.linksGroup} />}
                </div>
            </section>∫
        </Layout>
    )

}

export const fragment = graphql`
    fragment CookiesManagementWebFields on DatoCmsWebCookieManagement {
        seo {
          description
          title
        }
        cover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
        linksGroup {
            id
            title
            titleAttribute
            slug
            mainColor {
                hex
            }       
            icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            iconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIcon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
    }
    
    fragment CookiesManagementFields on DatoCmsCookiemanagement {
        content {
            __typename
            ... on DatoCmsTitle {
                ...TitleFields
            }
            ... on DatoCmsParagraph {
                ...ParagraphFields
            }
            ... on DatoCmsSubtitle {
                ...SubtitleFields
            }
            ... on DatoCmsPicturedParagraph {
                ...PicturedParagraphFields
            }
            ... on DatoCmsStatisticsBloc {
                ...StatisticsBlockFields
            }
            ... on DatoCmsDetailedOutlinedBloc {
                ...DetailedOutlinedBlockFields
            }
            ... on DatoCmsOutlinedBloc {
                ...OutlinedBlockFields
            }
            ... on DatoCmsTitleParagraphBlueBloc {
                ...TitleParagraphBlueBlockFields
            }
        }
    }
`

export const pageQuery = graphql`
    query CookiesManagement($locale: String!) {  
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }   
        datoCmsCookiemanagement(locale: {  eq: $locale }) {
            ...CookiesManagementFields
        }
        datoCmsWebCookieManagement(locale: {  eq: $locale }) {
            ...CookiesManagementWebFields
        }
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`


export default CookiesManagementPage;